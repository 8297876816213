<form (submit)="submit()" [formGroup]="formGroup">
  <div
    class="form-row"
    [class.invalid]="
      formGroup.controls.content.dirty && formGroup.controls.content.invalid
    "
  >
    <label for="new-todo"
      ><h1 *ngIf="!name">What do you want to do?</h1>
      <h1 *ngIf="name">Hi, {{ name | capitalize }}, what's on the agenda'?</h1>
    </label>
    <input
      id="new-todo"
      name="new-todo"
      autocomplete="off"
      type="text"
      formControlName="content"
    />
    <button type="submit">&gt;</button>
    <div
      class="hints"
      *ngIf="
        formGroup.controls.content.dirty && formGroup.controls.content.errors
      "
    >
      <div
        *ngIf="
          formGroup.controls.content.errors &&
          formGroup.controls.content.errors['required']
        "
      >
        {{ formGroup.controls.content.errors['required'].hint }}
      </div>
      <div
        *ngIf="
          formGroup.controls.content.errors &&
          formGroup.controls.content.errors['maxLength']
        "
      >
        {{ formGroup.controls.content.errors['maxLength'].hint }}
      </div>
    </div>
  </div>
</form>
