import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
  pure: true,
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string) {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
  }
}
