import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { getIsAuthenticated, getLogoutUrl } from '../../redux/app.selectors';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
})
export class NavComponent {
  isAuthenticated$ = this.store.select(getIsAuthenticated);
  logoutUrl$ = this.store.select(getLogoutUrl);

  @ViewChild('logoutForm')
  logoutForm!: ElementRef<HTMLFormElement>;

  constructor(private store: Store) {}

  logout() {
    this.logoutForm.nativeElement.submit();
  }
}
