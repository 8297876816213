import { createFeatureSelector, createSelector } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { todoAdapter } from '../types/todo-model.type';
import { APP_FEATURE_KEY } from './app.feature-key';
import { AppState } from './app.state';

export const getAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

export const getTodos = createSelector(getAppState, state =>
  todoAdapter.getSelectors().selectAll(state.todos),
);

export const getIncompleteTodos = createSelector(getTodos, todos =>
  todos.filter(todo => !todo.completed).reverse(),
);

export const getCompletedTodos = createSelector(getTodos, todos =>
  todos.filter(todo => todo.completed).reverse(),
);

export const getMe = createSelector(getAppState, state => state.me);

export const getIsAuthenticated = createSelector(getMe, me => !!me?.idToken);

export const getHasTriedToAuthenticate = createSelector(
  getAppState,
  state => state.hasTriedToAuthenticate,
);

export const getName = createSelector(
  getMe,
  me => me?.nickname ?? me?.name ?? null,
);

export const getLogoutUrl = createSelector(getMe, me => {
  const url = new URL('/oidc/logout', `https://${environment.AUTH0_DOMAIN}`);
  url.searchParams.set('client_id', environment.AUTH0_CLIENT_ID);
  url.searchParams.set('logout_hint', me?.idToken?.sid ?? '');
  url.searchParams.set(
    'post_logout_redirect_uri',
    new URL('/api/auth/forget', window.location.origin).href,
  );

  return url.href;
});

export const getIsSavingProfile = createSelector(
  getAppState,
  state => state.isSavingProfile,
);
