<ul>
  <li><a routerLink="/home" routerLinkActive="active">Home</a></li>
  <li><a routerLink="/about" routerLinkActive="active">About</a></li>
  <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
  <ng-container *ngIf="isAuthenticated$ | async as isAuthenticated">
    <li>&middot;</li>
    <li>
      <a routerLink="/profile" routerLinkActive="active">Profile</a>
    </li>
    <li>
      <a [href]="logoutUrl$ | async">Logout</a>
    </li>
  </ng-container>
</ul>
