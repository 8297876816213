import { createEntityAdapter } from '@ngrx/entity';

export type TodoModel = {
  id: string;
  created: string;
  updated: string;
  content: string;
  completed: boolean;
};

export const todoAdapter = createEntityAdapter<TodoModel>({
  selectId: todo => todo.id,
  sortComparer: (a, b) => a.updated.localeCompare(b.updated),
});

export const todoInitialState = todoAdapter.getInitialState();
