import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TodoModel } from '../../types/todo-model.type';

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrl: './todo-list.component.scss',
  animations: [
    trigger('todoAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate(
          '500ms ease',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
    ]),
  ],
})
export class TodoListComponent {
  @Input() todos: TodoModel[] | null = null;

  @Output()
  completeTodo = new EventEmitter<TodoModel>();

  @Output()
  undoCompleteTodo = new EventEmitter<TodoModel>();

  @Output()
  trashTodo = new EventEmitter<TodoModel>();

  activate(todo: TodoModel) {
    if (todo.completed) {
      this.undoComplete(todo);
    } else {
      this.complete(todo);
    }
  }

  complete(todo: TodoModel) {
    this.completeTodo.emit(todo);
  }

  undoComplete(todo: TodoModel) {
    this.undoCompleteTodo.emit(todo);
  }

  trash(event: Event, todo: TodoModel) {
    event.stopPropagation();

    this.trashTodo.emit(todo);
  }

  identity(_index: number, todo: TodoModel) {
    return todo.id;
  }
}
