import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { CapitalizePipe } from '../../pipes/capitalize.pipe';
import { maxLength, required } from '../../validators/required.validator';

@Component({
  selector: 'app-todo-input-field',
  templateUrl: './todo-input-field.component.html',
  styleUrl: './todo-input-field.component.scss',
  providers: [CapitalizePipe],
})
export class TodoInputFieldComponent {
  @Input() name: string | null = null;
  @Output() addTodo = new EventEmitter<string>();

  formGroup = this.fb.group({
    content: [
      '',
      [
        required('Please enter a todo'),
        maxLength(
          256,
          'Please enter a shorter todo, only 256 characters supported',
        ),
      ],
    ],
  });

  constructor(private fb: FormBuilder) {}

  submit() {
    if (!this.formGroup.valid) {
      return;
    }

    this.addTodo.emit(
      (this.formGroup.value.content as string)?.substring(0, 256),
    );

    this.formGroup.reset();
  }
}
