<ul>
  <li
    *ngFor="let todo of todos; trackBy: identity"
    @todoAnimation
    (click)="activate(todo)"
    (keypress)="activate(todo)"
    tabindex="0"
    [class.completed]="todo.completed"
  >
    <span>{{ todo.content }}</span>
    <div *ngIf="!todo.completed" class="actions">✔️</div>
    <div *ngIf="todo.completed" class="actions">
      <div
        (click)="trash($event, todo)"
        (keypress)="trash($event, todo)"
        tabindex="0"
      >
        🗑️
      </div>
    </div>
  </li>
</ul>
