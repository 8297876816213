import { ValidatorFn } from '@angular/forms';

export function required(hint: string): ValidatorFn {
  return control => {
    return control.value ? null : { required: { hint } };
  };
}

export function maxLength(maxLength: number, hint: string): ValidatorFn {
  return control => {
    return control.value?.length <= maxLength ? null : { maxLength: { hint } };
  };
}
