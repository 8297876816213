import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { MeModel } from '../types/me-mode.type';
import {
  AllTodosResponseEvent,
  StreamEvent,
  TodoCreatedEvent,
  TodoDeletedEvent,
  TodoUpdatedEvent,
} from '../types/stream-event.type';
import { TodoModel } from '../types/todo-model.type';
import { APP_FEATURE_KEY } from './app.feature-key';

export const {
  createTodoItem,
  saveTodoItem,
  updateTodoItem,
  completeTodoItem,
  undoCompleteTodoItem,
  loadManyTodos,
  loadTodosFromStorage,
  clearAllCompletedTodos,
  trashTodoItem,
  startApp,
  incomingStreamEvent,
  incomingAllTodosResponseStreamEvent,
  incomingTodoCreatedStreamEvent,
  incomingTodoDeletedStreamEvent,
  incomingTodoUpdatedStreamEvent,
  setMe,
  saveMe,
  triggerAuthentication,
  incomingNoopStreamEvent,
  savedMe,
} = createActionGroup({
  source: APP_FEATURE_KEY,
  events: {
    'create todo item': props<{ content: string }>(),
    'save todo item': props<{ todo: TodoModel }>(),
    'update todo item': props<{
      id: string;
      changes: Partial<Omit<TodoModel, 'created' | 'updated' | 'id'>>;
    }>(),
    'complete todo item': props<{ id: string }>(),
    'undo complete todo item': props<{ id: string }>(),
    'load todos from storage': emptyProps(),
    'load many todos': props<{ todos: TodoModel[] }>(),
    'clear all completed todos': emptyProps(),
    'trash todo item': props<{ id: string }>(),
    'start app': emptyProps(),
    'incoming stream event': props<{ event: StreamEvent }>(),
    'incoming todo created stream event': props<{ event: TodoCreatedEvent }>(),
    'incoming todo updated stream event': props<{ event: TodoUpdatedEvent }>(),
    'incoming todo deleted stream event': props<{ event: TodoDeletedEvent }>(),
    'incoming all todos response stream event': props<{
      event: AllTodosResponseEvent;
    }>(),
    'set me': props<{ me: MeModel }>(),
    'save me': props<{ name: string }>(),
    'saved me': props<{ me: MeModel }>(),
    'trigger authentication': emptyProps(),
    'incoming noop stream event': props<{ event: StreamEvent }>(),
  },
});
